const GET = "GET";
const POST = "POST";
const server_url = process.env.REACT_APP_SERVER_URL;
const livechatforce_server_url = process.env.REACT_APP_livechatforce_server_url;
const api_url = "https://api.livechatinc.com/";

const createFetchApiRequest = async (method, route, accessToken, data) => {
  try {
    const response = await fetch(route, {
      method: method,
      mode: "cors", // no-cors, *cors, same-origin
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: data,
    });

    if (!response.ok) {
      throw new Error(response);
    }
    return response;
  } catch (ex) {
    console.error(ex);
    return ex; // or return an appropriate error object
  }
};

const createFetchApiRequestWithoutHeader = async (method, route) => {
  try {
    const response = await fetch(route, {
      method: method,
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    return response;
  } catch (error) {
    console.error(error);
    return null; // or return an appropriate error object
  }
};

const createFetchApiPostRequestWithoutHeader = async (method, route, data) => {
  try {
    const response = await fetch(route, {
      method: method,
      // headers: {
      //   "Content-Type": "application/json",
      //   Authorization: headers,
      // },
      body: data,
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    return response;
  } catch (error) {
    console.log(error);
    return null; // or return an appropriate error object
  }
};

const createFetchApiPostRequestWithHeader = async (
  method,
  route,
  data,
  header_token
) => {
  try {
    const response = await fetch(route, {
      method: method,
      body: data,
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    return response;
  } catch (error) {
    console.log(error);
    return null; // or return an appropriate error object
  }
};

const api = {
  getInfo: (accessToken) =>
    createFetchApiRequest(GET, server_url + "v2/info", accessToken, null),

  getAccountInfo: (formData) =>
    createFetchApiPostRequestWithoutHeader(
      POST,
      livechatforce_server_url + "account_access",
      formData
    ),

  getUserGroup: (groupId) =>
    createFetchApiRequestWithoutHeader(
      GET,
      livechatforce_server_url +
        "get_live_auth?" +
        new URLSearchParams({ group_id: groupId })
    ),

  getChatHistory: (accessToken, chat_id, thread_id) =>
    createFetchApiRequest(
      POST,
      api_url + "v3.5/agent/action/get_chat",
      accessToken,
      JSON.stringify({ chat_id: chat_id, thread_id: thread_id })
    ),

  createAddLeadRequest: (formData, access_token) =>
    createFetchApiPostRequestWithHeader(
      POST,
      livechatforce_server_url + "create_tenant_lead",
      formData,
      access_token
    ),

  checkIsLeadExisit: (formData, access_token) =>
    createFetchApiPostRequestWithHeader(
      POST,
      livechatforce_server_url + "is_lead_exists",
      formData,
      access_token
    ),

  getScriptFlow: (tenant, lang) =>
    createFetchApiRequestWithoutHeader(
      GET,
      livechatforce_server_url +
        "script_flow?" +
        new URLSearchParams({ tenant: tenant, lang: lang })
    ),

  sendEventLiveChat: (accessToken, rawData) =>
    createFetchApiRequest(
      POST,
      api_url + "v3.5/agent/action/send_event",
      accessToken,
      rawData
    ),

  sendLoginRequest: (formData) =>
    createFetchApiPostRequestWithoutHeader(
      POST,
      livechatforce_server_url + "agent/login",
      formData
    ),

  sendLogoutRequest: (formData) =>
    createFetchApiPostRequestWithoutHeader(
      POST,
      livechatforce_server_url + "logout",
      formData
    ),

  sendChatUpdate: (formData) =>
    createFetchApiPostRequestWithoutHeader(
      POST,
      livechatforce_server_url + "tenant/update_chat",
      formData
    ),

  connectCall: (formData) =>
    createFetchApiPostRequestWithoutHeader(
      POST,
      livechatforce_server_url + "connect_call",
      formData
    ),

  getCallStatus: (formData) =>
    createFetchApiPostRequestWithoutHeader(
      POST,
      livechatforce_server_url + "get_call_status",
      formData
    ),

  getTenantAvailabilityTiming: (tenant, day) =>
    createFetchApiPostRequestWithoutHeader(
      GET,
      livechatforce_server_url +
        "get_call_time?" +
        new URLSearchParams({ tenant: tenant, day: day })
    ),

  updateLeadMeta: (formData) =>
    createFetchApiPostRequestWithoutHeader(
      POST,
      livechatforce_server_url + "update_lead_meta",
      formData
    ),

  checkUserCheckStatus: (formData) =>
    createFetchApiPostRequestWithoutHeader(
      POST,
      livechatforce_server_url + "agent/get-status",
      formData
    ),

  checkChatExist: (chatId, tenantName) =>
    createFetchApiPostRequestWithoutHeader(
      GET,
      livechatforce_server_url +
        "tenant/is_exist_chat?" +
        new URLSearchParams({ chat_id: chatId, tenant_name: tenantName })
    ),
  checkHaveRegions: (tenantName) =>
    createFetchApiPostRequestWithoutHeader(
      GET,
      livechatforce_server_url +
        "have_regions?" +
        new URLSearchParams({ tenant: tenantName })
    ),

  checkRegionAvailable: (zipCode, tenantName) =>
    createFetchApiPostRequestWithoutHeader(
      GET,
      livechatforce_server_url +
        "validate/region?" +
        new URLSearchParams({ zip: zipCode, tenant: tenantName })
    ),
};

export default api;
