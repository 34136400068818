import React, { useEffect, useState, useContext } from "react";
import api from "../../utils/api";
import { UserContext } from "../../index";
import { ErrorModal } from "../LeadGeneration/ErrorModal";

export const Region = ({ data }) => {
  const { tenantName } = useContext(UserContext);

  const [isActive, setIsActive] = useState(false);

  const [hasRegion, setHasRegion] = useState(false);
  const [regionAvailable, setRegionAvailable] = useState("");

  const [inputValue, setInputValue] = useState("");

  const [loading, setIsLoading] = useState(false);
  const showLoader = () => setIsLoading(true);
  const hideLoader = () => setIsLoading(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    isRegionAvailable();
  };

  const haveRegions = async () => {
    try {
      showLoader();
      const checkTenantHaveRegions = await api.checkHaveRegions(tenantName);
      const regionResponse = await checkTenantHaveRegions.json();
      const { message } = regionResponse;
      setHasRegion(message === "Avaiable");
      hideLoader();
    } catch (e) {
      hideLoader();
      console.log(e);
    }
  };

  async function isRegionAvailable() {
    try {
      showLoader();
      const checkTenantRegions = await api.checkRegionAvailable(
        inputValue,
        tenantName
      );
      const regionResponse = await checkTenantRegions.json();
      const { message } = regionResponse;
      setRegionAvailable(message);
      hideLoader();
      setInputValue("");
    } catch (e) {
      hideLoader();
      console.log(e);
    }
  }

  useEffect(() => {
    haveRegions();
  }, [tenantName]);

  const openErrorModal = (message) => {
    setErrorMessage(message);
    setIsErrorModalOpen(true);
  };

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorMessage("");
  };

  const handleChange = (event) => {
    setIsActive(event.target.value > 0);
    setInputValue(event.target.value);
  };

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <div className={data}>
      <div
        className="form-loader form-loader-submit"
        style={{ display: loading ? "flex" : "none" }}
      >
        <div className="preload-wrapper">
          <div className="preload-loader-wrapper">
            <div className="preload-loader"></div>
          </div>
        </div>
      </div>

      <div id="call-controls" className="form-wrapper">
        {hasRegion ? (
          <form>
            <label className="fb-select-label" htmlFor="postal-code">
              Enter region postal code
            </label>
            <input
              className="form-control"
              id="postal-code"
              required={true}
              aria-required="true"
              type="text"
              placeholder="7500"
              onChange={handleChange}
              value={inputValue}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "15px",
              }}
            >
              <button
                className="primary-btn"
                id="button-call"
                type="submit"
                onClick={isActive ? handleSubmit : (e) => e.preventDefault()}
                // onClick={handleSubmit}
                disabled={!isActive}
              >
                Submit
              </button>
            </div>
          </form>
        ) : (
          <p className="call-not-available">Regions Not Available</p>
        )}
        <p className={`${"call-not-available"}`}>{regionAvailable}</p>

        <ErrorModal
          isOpen={isErrorModalOpen}
          onClose={closeErrorModal}
          errorMessage={errorMessage}
        />
      </div>
    </div>
  );
};
