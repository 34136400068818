export const getCurrentDay = (timeZone) => {
  const currentDate = getTimeByTimezone(timeZone);
  // var currentDate = new Date();
  var currentDay = currentDate.getDay();
  let day = 0;
  switch (currentDay) {
    case 0: {
      day = "sunday";
      break;
    }
    case 1: {
      day = "monday";
      break;
    }
    case 2: {
      day = "tuesday";
      break;
    }
    case 3: {
      day = "wednesday";
      break;
    }
    case 4: {
      day = "thursday";
      break;
    }
    case 5: {
      day = "friday";
      break;
    }
    case 6: {
      day = "saturday";
      break;
    }
    default: {
      day = "monday";
      break;
    }
  }
  return day;
};

export const isCurrentTimeInRange = (time_from, time_to, timezone) => {
  // Create Date objects for the current time, time_from, and time_to
  if (time_to === "00:00:00") {
    time_to = "23:59:00";
  }
  const currentTime = getTimeByTimezone(timezone);
  const fromTime = new Date(currentTime.toDateString() + " " + time_from);
  const toTime = new Date(currentTime.toDateString() + " " + time_to);

  // Check if the current time is between time_from and time_to
  return currentTime >= fromTime && currentTime <= toTime;
};

export const getTimeByTimezone = (timezone) => {
  //   const timeZone = "America/New_York";
  const options = {
    timeZone: timezone,
    hour12: false,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  };

  const formatter = new Intl.DateTimeFormat("en-US", options);
  const currentTimeInTimezone = formatter.format(new Date());
  // Convert the formatted string to a Date object
  const currentTime = new Date(currentTimeInTimezone);
  return currentTime;
};

export const getLastPageUrl = (users) => {
  let globalLastPageUrl;
  users.forEach((user) => {
    if (user.last_visit) {
      if (user.last_visit.last_pages && user.last_visit.last_pages.length > 0) {
        const lastPage = user.last_visit.last_pages[0];
        globalLastPageUrl = lastPage.url;
      }
    }
  });
  return globalLastPageUrl;
};
