import "./style.css";
import React, { useEffect, useState, useContext } from "react";
import Modal from "react-modal";
import { UserContext } from "../../index";
import api from "../../utils/api";
import { ErrorModal } from "./ErrorModal";

Modal.setAppElement("#root");

export const EditModal = ({ isOpen, onClose, data }) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: "80%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
      padding: 0,
    },
  };

  const { accessToken, profile } = useContext(UserContext);

  const [title, setTitle] = useState("");
  const [buttonList, setButtonList] = useState([]);

  const handleData = () => {
    if (data) {
      const { elements } = JSON.parse(data);
      setTitle(elements[0].title);
      setButtonList(elements[0].buttons);
      setTimeout(() => {
        const txtFieldValue = document.getElementById("send");
        if (txtFieldValue) {
          txtFieldValue.value = elements[0].title;
        }
      }, 50);
    }
  };

  const onCloseClick = (label) => {
    setButtonList(buttonList.filter((e) => e.text !== label));
  };

  const getButtonObject = (label) => {
    return {
      type: "message",
      text: label,
      postback_id: "send_message",
      user_ids: [],
      value: label,
    };
  };

  const createJsonData = (title) => {
    var jsonObj = JSON.parse(data);
    var type = jsonObj["type"];
    var template_id = jsonObj["template_id"];
    // var title = jsonObj["elements"][0]["title"];
    return {
      type: type,
      template_id: template_id,
      elements: [{ title: title, buttons: buttonList }],
    };
  };

  const sendData = async () => {
    const txtFieldValue = document.getElementById("send");

    if (txtFieldValue.value.trim() == "") {
      return;
    }

    showLoader();
    setTitle(txtFieldValue.value);
    var data = createJsonData(txtFieldValue.value);

    try {
      let chatObj = profile["chat"];
      let chat_id = chatObj["chat_id"];

      const rawData = {
        chat_id: chat_id,
        event: data,
      };

      const sendEvent = await api.sendEventLiveChat(
        accessToken,
        JSON.stringify(rawData)
      );
      const sendEventResponse = await sendEvent.json();
      hideLoader();
      onClose();
    } catch (e) {
      console.log(e);
      hideLoader();
      openErrorModal("Chat not active!");
    }
  };

  const [loading, setIsLoading] = useState(false);
  const showLoader = () => setIsLoading(true);
  const hideLoader = () => setIsLoading(false);

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const openErrorModal = (message) => {
    setErrorMessage(message);
    setIsErrorModalOpen(true);
  };

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorMessage("");
  };

  useEffect(() => {
    handleData();
  }, [data]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Edit Modal"
      style={customStyles}
    >
      <div className="modal-content editModal">
        <div className="modal-bottom ">
          <h2>Edit</h2>
          {/* <p>{title}</p> */}
          <div className="form-group add-group">
            <textarea
              id="send"
              placeholder="Edit Label"
              class="form-control"
            ></textarea>
            <span>
            <input
              type="button"
              className="primary-btn"
              value="Send"
              onClick={() => sendData()}
            /></span>
          </div>
{/* 
          <div
            style={{
              margin: "10px 0",
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          ></div> */}
        </div>

        <div
          className="form-loader form-loader-submit"
          style={{ display: loading ? "flex" : "none" }}
        >
          <div className="preload-wrapper">
            <div className="preload-loader-wrapper">
              <div className="preload-loader"></div>
            </div>
          </div>
        </div>

        <ErrorModal
          isOpen={isErrorModalOpen}
          onClose={closeErrorModal}
          errorMessage={errorMessage}
        />
      </div>
    </Modal>
  );
};
