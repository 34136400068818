import React, { useState, useEffect, createContext } from "react";
import ReactDOM from "react-dom";
import { createGlobalStyle } from "styled-components";
import "@livechat/design-system/dist/design-system.css";
import AccountsSDK from "@livechat/accounts-sdk";
import config from "./utils/config.js";
import Spinner from "./components/Spinner";
import { App } from "../src/components/index.js";
import ReactGA from "react-ga4";

const GlobalStyle = createGlobalStyle`
  ::-webkit-scrollbar {
      display: none;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
    overflow-x: hidden;
  }
`;

const useLiveChat = ({ client_id }) => {
  const [accessToken, setAccessToken] = useState(null);
  const accountsSDK = new AccountsSDK({
    client_id: client_id,
  });

  useEffect(() => {
    const authorize = async () => {
      try {
        const authorizeData = await accountsSDK.redirect().authorizeData();
        accountsSDK.verify(authorizeData);
        const { access_token } = authorizeData;
        setAccessToken(access_token);
        console.log(access_token);
      } catch (error) {
        console.log(error);
        await accountsSDK.redirect().authorize();
      }
    };
    authorize();
  }, []);

  return [accessToken];
};

export const UserContext = createContext();
const AppWithAuth = () => {
  const [accessToken] = useLiveChat(config);
  const [accountId, setAccountId] = useState(null);
  const [tenantName, setTenantName] = useState("");
  const [twilioNumber, setTwilioNumber] = useState(null);
  const [contactNumber, setContactNumber] = useState(null);
  const [tenantFormData, setTenantFormData] = useState("");
  const [scriptFlow, setScriptFlow] = useState("");
  const [profile, setProfile] = useState(null);
  const [info, setInfo] = useState(new Map());
  const [user, setUser] = useState(null);
  const [isTenantAvailable, setTenantAvailable] = useState(false);
  const [customObj, setCustomObj] = useState({});
  const [leadId, setLeadId] = useState(null);
  const [callId, setCallId] = useState(null);
  const [trackingId, setTrackingId] = useState(null);

  const contextObject = {
    accessToken,
    tenantName,
    setTenantName,
    tenantFormData,
    setTenantFormData,
    scriptFlow,
    setScriptFlow,
    profile,
    setProfile,
    info,
    setInfo,
    user,
    setUser,
    customObj,
    setCustomObj,
    twilioNumber,
    setTwilioNumber,
    contactNumber,
    setContactNumber,
    isTenantAvailable,
    setTenantAvailable,
    leadId,
    setLeadId,
    callId,
    setCallId,
    accountId,
    setAccountId,
    setTrackingId,
    trackingId,
  };

  useEffect(() => {
    if (trackingId) {
      ReactGA.initialize(trackingId, {
        gaOptions: {
          cookieFlags: "SameSite=None; Secure",
        },
      });
    }
  }, [trackingId]);

  if (!accessToken) {
    return <Spinner marginTop="calc(100% - 50px)" />;
  }

  return (
    <UserContext.Provider value={contextObject}>
      <GlobalStyle />
      <App />
    </UserContext.Provider>
  );
};

ReactDOM.render(<AppWithAuth />, document.getElementById("root"));
