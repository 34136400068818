import "./style.css";
import React, { useState, useContext, useCallback } from "react";
import api from "../../utils/api";
import { UserContext } from "../../index";
import { ErrorModal } from "./ErrorModal";
import Modal from "react-modal";

export const AgentLogin = ({ isOpen, onClose }) => {
  const customStyles = {
    content: {
      top: "0%",
      left: "0%",
      right: "auto",
      bottom: "auto",
      //   marginRight: '-50%',
      width: "100%",
      //   transform: 'translate(-50%, -50%)',
      textAlign: "center",
      padding: 0,
    },
  };

  const { user, setUser } = useContext(UserContext);

  const [loading, setIsLoading] = useState(false);
  const showLoader = () => setIsLoading(true);
  const hideLoader = () => setIsLoading(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    let formData = new FormData();
    for (let i = 0; i < event.target.length; i++) {
      formData.append(event.target[i].name, event.target[i].value.trim());
    }
    sendUserLoginRequest(formData);
  };

  const sendUserLoginRequest = async (formData) => {
    try {
      showLoader();
      const jsonResponseData = await api.sendLoginRequest(formData);
      const jsonResponse = await jsonResponseData.json();
      const { user } = jsonResponse;
      localStorage.setItem("user", JSON.stringify(user));

      setUser(user);
      onClose();
      hideLoader();
    } catch (ex) {
      hideLoader();
      handleError("Invalid login credentials!");
    }
  };

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const openErrorModal = (message) => {
    setErrorMessage(message);
    setIsErrorModalOpen(true);
  };

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorMessage("");
  };

  // Simulate an error and open the error modal
  const handleError = (msg) => {
    openErrorModal(msg);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Edit Modal"
      style={customStyles}
    >
      <div>
        <div className="login-formWrapper">
          <form
            className="login-form"
            name="btn-submit"
            action="/"
            method="Post"
            onSubmit={handleSubmit}
          >
            <h2>Login</h2>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Email"
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                name="password"
                className="form-control"
                placeholder="Password"
              />
            </div>
            <input type="submit" className="primary-btn"></input>
          </form>
          <div
            className="form-loader form-loader-submit"
            style={{ display: loading ? "flex" : "none" }}
          >
            <div className="preload-wrapper">
              <div className="preload-loader-wrapper">
                <div className="preload-loader"></div>
              </div>
            </div>
          </div>

          <ErrorModal
            isOpen={isErrorModalOpen}
            onClose={closeErrorModal}
            errorMessage={errorMessage}
          />
        </div>
      </div>
    </Modal>
  );
};
