import React, { useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

export const ErrorModal = ({ isOpen, onClose, errorMessage }) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: "80%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
      padding: 0,
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Error Modal"
      style={customStyles}
    >
      <div className="modal-content modal-error">
        <div className="modal-top">
          <div className="cross-circle">
            <svg
              id="svg"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-7 -2 139 139"
            >
              <circle
                class="path circle"
                fill="none"
                stroke-width="10"
                stroke-miterlimit="10"
                stroke-linecap="round"
                cx="60"
                cy="65.1"
                r="62.1"
              />

              <path
                class="cross-line1"
                stroke="#870000"
                stroke-width="10"
                stroke-miterlimit="10"
                stroke-linecap="round"
                d="M25, 34 L93, 94"
              ></path>
              <path
                class="cross-line2"
                stroke="#870000"
                stroke-width="10"
                stroke-miterlimit="10"
                stroke-linecap="round"
                d="M90, 31 L29, 97"
              ></path>
            </svg>
          </div>
        </div>
        <div className="modal-bottom">
          <h2>Error</h2>
          <p>{errorMessage}</p>

          <button onClick={onClose} className="primary-btn">
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};
