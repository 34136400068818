import "./style.css";
import "./question.css";
import $, { error, event } from "jquery";
import React, { useEffect, useState, useContext } from "react";
import { createDetailsWidget } from "@livechat/agent-app-sdk";
import api from "../../utils/api";
import { UserContext } from "../../index";
import { ScriptFlowDetail } from "./ScriptFlowDetail";
import { TwilioVoice } from "../voice/TwilioVoice";
import { Region } from "../Region/Region";
import { AgentLogin } from "./AgentLogin";
import ReactGA from "react-ga4";

import { getLastPageUrl } from "../../utils/helper";
window.jQuery = $; //JQuery alias
window.$ = $; //JQuery alias

// import "./script.js";
require("jquery-ui-sortable"); //For FormBuilder Element Drag and Drop
require("formBuilder"); // For FormBuilder
require("formBuilder/dist/form-render.min.js");

export const FormBuilder = () => {
  const {
    accessToken,
    tenantName,
    setTenantName,
    profile,
    setProfile,
    tenantFormData,
    setTenantFormData,
    user,
    setUser,
    customObj,
    setCustomObj,
    setTwilioNumber,
    setContactNumber,
    isTenantAvailable,
    setLeadId,
    callId,
    accountId,
    trackingId,
    setTrackingId,
  } = useContext(UserContext);

  const [success, setSuccess] = useState(false);
  const [widget, setWidget] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [leadSubmit, setLeadSubmit] = useState(false);
  const [leadType, setLeadType] = useState(null);
  const [sourceClientId, setSourceClientId] = useState("");
  const [analyticsId, setAnalyticsId] = useState(null);
  const [referrer, setReferrer] = useState(null);
  const [isLeadEventEnabled, setLeadEventEnabled] = useState("0");
  const [profileState, setProfileState] = useState(null);
  const [tenantIdentifier, setTenantIdentifier] = useState("");
  const [scriptForm, setScriptForm] = useState("");
  const [callToAction, setCallToAction] = useState("");
  const [hasRegion, setHasRegion] = useState(0);

  const handleSubmit = (event) => {
    event.preventDefault();

    let formData = new FormData();
    for (let i = 0; i < event.target.length; i++) {
      if (event.target[i].type == "checkbox") {
        if (event.target[i].checked == true) {
          formData.append(event.target[i].name, event.target[i].value);
        }
      } else {
        formData.append(event.target[i].name, event.target[i].value);
      }
    }

    formData.append("tenant_name", tenantName);
    // formData.append("source", "3");

    var email = formData.get("email");
    var phoneNumber = formData.get("phonenumber");

    if (!email && !phoneNumber) {
      showToast();
      return;
    }

    if (callId) {
      formData.append("call_id", callId);
    }

    const { customVariables } = profile;
    const { source } = customVariables;

    if (source) {
      formData.append("custom_source", source);
    }

    showLoader();
    if (profileState) {
      formData.append("analytics", JSON.stringify(profileState["geolocation"]));
      let chatObj = profileState["chat"];
      getChatHistory(chatObj["chat_id"], chatObj["thread_id"], formData, false);
      return;
    }

    addTenantLeadRequest(formData);
  };

  const addTenantLeadRequest = async (formData) => {
    try {
      startTimer();
      const storedUser = localStorage.getItem("user");
      const { access_token, staffid } = JSON.parse(storedUser);
      formData.append("staff_id", staffid);
      // formData.append("referrer", referrer);

      const jsonResponseData = await api.createAddLeadRequest(
        formData,
        access_token
      );
      const jsonResponse = await jsonResponseData.json();
      const { lead_id } = jsonResponse;

      if (analyticsId) {
        if (isLeadEventEnabled === "1") {
          triggerAnalyticsEvent("new_lead");
        }
      }

      setLeadId(lead_id);
      console.log(jsonResponse);
      setSuccess(!success);
      openModal();
      hideLoader();
      // startTimer();
      setLeadSubmit(true);
    } catch (e) {
      hideLoader();
      console.log(e);
    }
  };

  const getTenantForm = async (profileObj) => {
    try {
      showLoader();
      //get users tenant information using email
      var chatObj = profileObj["chat"];
      var groupId = chatObj["groupID"];
      const accountUserGroup = await api.getUserGroup(groupId);
      const accountUserGroupResponse = await accountUserGroup.json();
      const {
        name,
        form,
        twilio_number,
        country_code,
        contact_number,
        analytics_id,
        lead_event_enable,
        client_script,
        region_available,
      } = accountUserGroupResponse;

      let { html, call_to_action } = client_script;

      var number = twilio_number.startsWith("+1")
        ? twilio_number
        : country_code.concat(twilio_number);

      setTenantIdentifier(name);
      setTenantName(name);
      setTenantFormData(null);
      setLeadId(null);
      setTenantFormData(form);
      setTwilioNumber(number);
      setContactNumber(contact_number);
      setLeadEventEnabled(lead_event_enable);
      setScriptForm(html);
      setCallToAction(call_to_action);
      setHasRegion(region_available);
      //if analytics id is present then set tracking id
      if (analytics_id) {
        setAnalyticsId(analytics_id);
        setTrackingId(analytics_id);
        setTimeout(() => {
          // setRoutingEventInAnalytics(profileObj);
        }, 5000);
      }
      createCustomObject(name, profileObj);
      hideLoader();
    } catch (e) {
      setTenantName("");
      setTenantFormData(null);
      hideLoader();
      console.log(e);
    }
  };

  const triggerAnalyticsEvent = (eventName) => {
    ReactGA.event({
      category: "Leads",
      action: eventName,
      label: eventName,
    });
  };

  const checkIfUserStatusOnline = async () => {
    try {
      let formData = new FormData();
      formData.append("account_id", accountId);
      console.log(accountId);
      const jsonResponseData = await api.checkUserCheckStatus(formData);
      const jsonResponse = await jsonResponseData.json();
      const { status } = jsonResponse;
      if (status !== "accepting_chats") {
        logoutUser();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getChatHistory = async (chat_id, thread_id, formData, fromEvent) => {
    try {
      const jsonResponseData = await api.getChatHistory(
        accessToken,
        chat_id,
        thread_id
      );

      const jsonResponse = await jsonResponseData.json();
      const { thread } = jsonResponse;
      const { events } = thread;

      const clientId = jsonResponse.properties.source.client_id;
      setSourceClientId(clientId);

      const routingReferrer = jsonResponse.thread.properties.routing.referrer;
      setReferrer(routingReferrer);

      const storedUser = localStorage.getItem("user");
      const { staffid } = JSON.parse(storedUser);

      const { users } = jsonResponse;
      let visited_page = getLastPageUrl(users);
      console.log("visited_page", visited_page);
      formData.append("agent_id", staffid);
      formData.append("visited_page", visited_page);

      formData.append("chat", JSON.stringify(events));
      formData.append("chat_id", chat_id);
      if (fromEvent) {
        sendUpdateChatEvent(formData);
      } else {
        formData.append("source", visited_page);
        if (clientId) {
          formData.append("client_id", clientId);
        }
        if (
          routingReferrer === null ||
          typeof routingReferrer === "undefined"
        ) {
          formData.append("origin", "N/A");
          formData.append("referrer", "N/A");
          formData.append("medium", "N/A");
        } else {
          formData.append("origin", routingReferrer);
          formData.append("referrer", routingReferrer);
          formData.append("medium", routingReferrer);
        }

        addTenantLeadRequest(formData);
      }
    } catch (e) {
      hideLoader();
      console.log(e);
    }
  };

  const setRoutingEventInAnalytics = async (profile) => {
    try {
      let chatObj = profile["chat"];

      const jsonResponseData = await api.getChatHistory(
        accessToken,
        chatObj["chat_id"],
        chatObj["thread_id"]
      );

      const jsonResponse = await jsonResponseData.json();
      const routingReferrer = jsonResponse.thread.properties.routing.referrer;
      console.log("routing_referrer" + routingReferrer);
      if (routingReferrer) {
        triggerAnalyticsEvent("routing");
      }
    } catch (e) {}
  };

  function onCustomerProfile(profile) {
    checkLoginStatus();
    setProfile(profile);
    setProfileState(profile);
    console.log("profile=============>", profile);
    getTenantForm(profile);
    stopTimer();
  }

  const checkLoginStatus = () => {
    const storedUser = localStorage.getItem("user");
    if (storedUser == null || storedUser == "null") {
      openLoginModal();
    }
  };

  const checkLeadStatus = async () => {
    try {
      const { tenant_name, chat_id } = customObj;
      if (tenant_name) {
        let formData = new FormData();
        formData.append("tenant_name", tenant_name);
        formData.append("chat_id", chat_id);

        const jsonResponseData = await api.checkIsLeadExisit(formData);
        const jsonResponse = await jsonResponseData.json();
        console.log(jsonResponse);
        const { status, lead_id, lead_type } = jsonResponse;
        if (status) {
          setLeadSubmit(true);
          setLeadId(lead_id);
          setLeadType(lead_type);
        } else {
          setLeadSubmit(false);
          setLeadType(null);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const sendLogoutRequest = async () => {
    try {
      const storedUser = localStorage.getItem("user");
      const { staffid } = JSON.parse(storedUser);
      let formData = new FormData();
      formData.append("staff_id", staffid);

      const jsonResponseData = await api.sendLogoutRequest(formData);
      const jsonResponse = await jsonResponseData.json();
    } catch (e) {
      console.log(e);
    }
  };

  const logoutUser = () => {
    sendLogoutRequest();
    setUser(null);
    localStorage.setItem("user", null);
    openLoginModal();
  };

  const startTimer = () => {
    setIsActive(true);
    setTimeout(() => {
      setIsActive(false);
    }, 120000);
  };

  const stopTimer = () => {
    setIsActive(false);
  };

  const checkChatExist = async () => {
    const { chat_id, tenant_name } = customObj;
    try {
      const jsonResponseData = await api.checkChatExist(chat_id, tenant_name);
      const jsonResponse = await jsonResponseData.json();
      const { exist } = jsonResponse;
      if (!exist) {
      }
      setUpdateChatData();
    } catch (e) {
      console.log(e);
    }
  };

  const setUpdateChatData = () => {
    const { tenant_name, chat_id, thread_id } = customObj;
    let formData = new FormData();
    formData.append("tenant_name", tenant_name);
    // let chatObj = profileObj["chat"];
    if (chat_id) {
      getChatHistory(chat_id, thread_id, formData, true);
    }
  };

  const sendUpdateChatEvent = async (formData) => {
    try {
      const jsonResponseData = await api.sendChatUpdate(formData);
      const jsonResponse = await jsonResponseData.json();
      console.log(jsonResponse);
    } catch (e) {
      console.log(e);
    }
  };

  const createCustomObject = (name, profileObj) => {
    if (profileObj) {
      let chatObj = profileObj["chat"];
      const data = {
        tenant_name: name,
        chat_id: chatObj["chat_id"],
        thread_id: chatObj["thread_id"],
      };
      setCustomObj(data);
    }
  };

  const reloadFrame = () => {
    window.location.reload(); // This reloads the current document
  };

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    console.log("storeduser ======>", storedUser);

    checkChatExist();
    checkLeadStatus();
  }, [customObj]);

  document.body.style.margin = "10px"; //For add margin in HTML body

  useEffect(() => {
    createDetailsWidget().then(setWidget);
  }, []);

  useEffect(() => {
    if (widget) {
      widget.on("customer_profile", onCustomerProfile);
    }

    return () => {
      if (widget) {
        widget.off("customer_profile", onCustomerProfile);
      }
    };
  }, [user, widget]);

  useEffect(() => {
    if (tenantFormData) {
      $("#fb-editor").formRender({ formData: tenantFormData });
    } else {
      $("#fb-editor").formRender({ formData: {} });
    }
  }, [tenantFormData, success]);

  useEffect(() => {
    // window.addEventListener("beforeunload", logoutUser);

    return () => {
      window.addEventListener("beforeunload", {});
    };
  }, [user]);

  const [activeIndex, setActiveIndex] = useState(1);
  const handleClick = (index) => setActiveIndex(index);
  const checkActive = (index, className) =>
    activeIndex === index ? className : "";

  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const [loading, setIsLoading] = useState(false);
  const showLoader = () => setIsLoading(true);
  const hideLoader = () => setIsLoading(false);

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const openLoginModal = () => {
    setIsLoginModalOpen(true);
  };

  const closeloginModal = () => {
    setIsLoginModalOpen(false);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function showToast() {
    var toast = document.getElementById("errorToast");
    toast.className = "show";
    setTimeout(function () {
      toast.className = toast.className.replace("show", "");
    }, 3000);
  }

  const checkKeyDown = (e) => {
    const focusedElement = document.activeElement;

    // Check if the focused element is an input field
    if (focusedElement.tagName === "INPUT") {
      if (focusedElement.type === "submit") {
        console.log("Elemenet", e.key);
        if (e.key === "Enter" || e.key === " ") e.preventDefault();
      }
    }
  };

  return (
    <div className="rightSideWrap">
      <div className="container-header-form">
        <p className="text-header">{capitalizeFirstLetter(tenantName)}</p>
        <button
          // className={`tab ${checkActive(2, "active")}`}
          onClick={reloadFrame}
        >
          Reload
        </button>
        <button className="logoutBtnNew" onClick={logoutUser}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 12H19M19 12L16 15M19 12L16 9"
              stroke="#18181B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19 6V5C19 3.89543 18.1046 3 17 3H7C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V18"
              stroke="#18181B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>

      <div className="tabs">
        <button
          className={`tab ${checkActive(1, "active")}`}
          onClick={() => handleClick(1)}
        >
          Form
        </button>

        <button
          className={`tab ${checkActive(2, "active")}`}
          onClick={() => handleClick(2)}
        >
          Script
        </button>
        <button
          className={`tab ${checkActive(3, "active")}`}
          onClick={() => handleClick(3)}
        >
          Voice
          <span
            className={`statusDot  ${
              isTenantAvailable ? "available" : "notavailable"
            }`}
          ></span>
        </button>

        {hasRegion === 1 ? (
          <button
            className={`tab ${checkActive(4, "active")}`}
            onClick={() => handleClick(4)}
          >
            Region
          </button>
        ) : null}
      </div>

      <div className="panels">
        <div className={`panel ${checkActive(1, "active")}`}>
          <div className="form-wrapper">
            <form
              name="btn-submit"
              action="/"
              method="Post"
              onKeyDown={(e) => checkKeyDown(e)}
              onSubmit={!isActive ? handleSubmit : (e) => e.preventDefault()}
            >
              <div id="fb-editor"></div>
              {leadSubmit ? (
                <div className="subBtn" style={{ textAlign: "center" }}>
                  <p className="lead-success">Lead submitted!.</p>
                </div>
              ) : (
                ""
              )}

              {leadType ? (
                <div className="subBtn" style={{ textAlign: "center" }}>
                  <p className="lead-success">LeadType: {leadType}</p>
                </div>
              ) : (
                ""
              )}

              {tenantFormData && (
                <div className="subBtn">
                  <input type="submit" value="Submit" className="primary-btn" />
                </div>
              )}

              {/* <button onClick={showToast}>Show toast</button> */}
              <div id="errorToast">
                Phone number / email is required for submitting the lead.
              </div>
            </form>
          </div>

          <div>
            {isOpen && (
              <div className="modal">
                <div className="modal-content">
                  <div className="modal-top">
                    <div className="checkmark-circle">
                      <div className="background"></div>
                      <div className="checkmark draw"></div>
                    </div>
                  </div>
                  <div className="modal-bottom">
                    <h2>Success</h2>
                    <button onClick={closeModal} className="primary-btn">
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div
              className="form-loader form-loader-submit"
              style={{ display: loading ? "flex" : "none" }}
            >
              <div className="preload-wrapper">
                <div className="preload-loader-wrapper">
                  <div className="preload-loader"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ScriptFlowDetail
          data={`panel ${checkActive(2, "active")}`}
          widget={widget}
          tenantIdentifier={tenantIdentifier}
          client_script={scriptForm}
          call_to_action={callToAction}
        />

        <TwilioVoice
          data={`panel ${checkActive(3, "active")}`}
          tenantIdentifier={tenantIdentifier}
        />

        <Region data={`panel ${checkActive(4, "active")}`} />

        <AgentLogin isOpen={isLoginModalOpen} onClose={closeloginModal} />
      </div>
    </div>
  );
};
