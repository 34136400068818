const config = {
  client_id: "eb9a6e77686798e0623b942088108f45",
  // client_id: "e1f910be7f56fc391e8d2c785845eb7d",
  // client_id: "8ad1cacd2c9a6e190453fe9906029400",
  server_url: "https://accounts.livechat.com/",
  livechatforce_server_url: "https://livechatforce.app/api/",
  api_url: "https://api.livechatinc.com/",
  account_url: "https://accounts.livechatinc.com/",
  accessToken:
    "NTQ2OGEzOGEtNDk1NC00OGQ1LWFhNjItZGZkYzIxMTk4N2JkOmRhbDppZl8xM1ZYenpyeDkyenByZ2RuS3VIcDVfMU0=",
};
export default config;
