import "./style.css";
import "./question.css";
import $, { error, event } from "jquery";
import React, { useEffect, useState, useContext, useCallback } from "react";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import api from "../../utils/api";
import { UserContext } from "../../index";
import { ErrorModal } from "./ErrorModal";
import { EditModal } from "./EditModal";

window.jQuery = $; //JQuery alias
window.$ = $; //JQuery alias

export const ScriptFlowDetail = ({
  data,
  widget,
  tenantIdentifier,
  client_script,
  call_to_action,
}) => {
  const {
    accessToken,
    //  tenantName,
    profile,
    scriptFlow,
    setScriptFlow,
  } = useContext(UserContext);

  const [selectedLanguage, setSelectedLanguage] = useState("english"); // Default language
  const [loading, setIsLoading] = useState(false);
  const [callToAction, setcallToAction] = useState("");
  const showLoader = () => setIsLoading(true);
  const hideLoader = () => setIsLoading(false);

  const handler = useCallback(
    (event) => {
      let objtext = $(event.currentTarget).attr("data-event-data");
      if (event.target.className == "choice_edit") {
        editHandle(atob(objtext));
        return;
      }
      sendScriptEvent(atob(objtext));
    },
    [profile]
  );

  // Function to handle language selection
  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const sendScriptEvent = async (data) => {
    let templateObj = JSON.parse(data);
    let templateId = templateObj["type"];
    if (templateId == "message" || templateId == "statement") {
      if (widget) {
        widget.putMessage(templateObj["text"].trim()).then(() => {
          // the text should be appended now
        });
      }
      return;
    }
    if (profile) {
      sendData(data);
    }
  };

  const sendData = async (data) => {
    showLoader();
    try {
      let chatObj = profile["chat"];
      let chat_id = chatObj["chat_id"];

      const rawData = {
        chat_id: chat_id,
        event: JSON.parse(data),
      };

      const sendEvent = await api.sendEventLiveChat(
        accessToken,
        JSON.stringify(rawData)
      );
      const sendEventResponse = await sendEvent.json();
      hideLoader();
    } catch (e) {
      hideLoader();
      openErrorModal("Chat not active!");
    }
  };

  const getScriptFlow = async () => {
    try {
      if (tenantIdentifier && tenantIdentifier !== "undefined") {
        var lang = selectedLanguage == "english" ? "en" : "es";
        showLoader();
        const userScriptFlow = await api.getScriptFlow(tenantIdentifier, lang);
        const userScriptFlowResponse = await userScriptFlow.json();
        const { html, call_to_action } = userScriptFlowResponse;
        setcallToAction(call_to_action);
        setScriptFlow(html);
        hideLoader();
      } else {
        setScriptFlow(null);
      }
    } catch (e) {
      hideLoader();
      setScriptFlow(null);
      console.log(e);
    }
  };

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editModalData, setEditModalData] = useState(null);

  const openErrorModal = (message) => {
    setErrorMessage(message);
    setIsErrorModalOpen(true);
  };

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorMessage("");
  };

  const openEditModal = (data) => {
    setEditModalData(data);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalData(null);
    setIsEditModalOpen(false);
  };

  // Simulate an error and open the error modal
  const handleError = (msg) => {
    openErrorModal(msg);
  };

  const editHandle = (data) => {
    openEditModal(data);
  };

  useEffect(() => {
    $("body").on("click", ".menu-list li", handler);
    return () => {
      $("body").off("click", ".menu-list li", handler);
    };
  }, [handler]);

  useEffect(() => {
    closeEditModal();
    $("#renderHtml").html(ReactHtmlParser(""));
    getScriptFlow();
  }, [tenantIdentifier, selectedLanguage]);

  useEffect(() => {
    $("#renderHtml").html(ReactHtmlParser(scriptFlow));
    if ($("#renderHtml").find(".call_to_action").length == 1) {
      $("#renderHtml").find(".call_to_action").html(callToAction);
    }
  }, [scriptFlow]);

  useEffect(() => {
    setScriptFlow(client_script);
  }, [client_script]);

  useEffect(() => {
    setcallToAction(call_to_action);
    if ($("#renderHtml").find(".call_to_action").length == 1) {
      $("#renderHtml").find(".call_to_action").html(call_to_action);
    }
  }, [call_to_action]);

  useEffect(() => {
    // Function to collapse all elements
    function collapseAllElements() {
      $(".sblock").find("ul").stop().slideUp();
    }

    // Function to expand a specific element
    function expandElement(element) {
      $(element).find("ul").stop().slideDown();
    }

    // Event handler for search input
    $("body").on("click", "#search_btn", function (event) {
      const searchText = $("#search-input").val().toLowerCase();

      if (searchText == "") {
        return false;
      }
      // Collapse all elements initially
      collapseAllElements();

      // Loop through each collapsible element
      $(".sblock").each(function () {
        const elementContent = $(this).find("ul").text().toLowerCase();
        if (elementContent.includes(searchText)) {
          expandElement(this);
        }
      });
    });

    $("body").on("click", ".sblock label", function () {
      $(this).parents(".sblock").find("ul").stop().slideToggle();
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      $("#renderHtml")
        .find("li")
        .each(function () {
          if ($(this).attr("data-typ") == "choice") {
            if ($(this).find(".choice_edit").length < 1) {
              var button = $(`<button class="choice_edit">...</button>`);
              $(this).append(button);
            }
          }
        });
    }, 50);
  }, [scriptFlow]);

  return (
    <div className={data}>
      <div
        className="form-loader form-loader-submit"
        style={{ display: loading ? "flex" : "none" }}
      >
        <div className="preload-wrapper">
          <div className="preload-loader-wrapper">
            <div className="preload-loader"></div>
          </div>
        </div>
      </div>
      <div className="search-language">
        <div className="search">
          <input type="text" id="search-input" placeholder="Search" />
          <button id="search_btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clip-path="url(#clip0_792_5063)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.7471 15.907C14.7521 15.9021 14.7571 15.8972 14.7621 15.8924C14.7672 15.8875 14.7723 15.8828 14.7774 15.8781C17.159 13.588 17.2412 9.80107 14.9575 7.40969C12.669 5.01321 8.87099 4.92572 6.47451 7.21429C4.07803 9.50285 3.99054 13.3008 6.27911 15.6973C8.5629 18.0888 12.3498 18.1809 14.7471 15.907ZM15.3777 17.9849C12.1838 20.3546 7.65029 20.029 4.8327 17.0786C1.78128 13.8833 1.89793 8.8193 5.09324 5.76788C8.28855 2.71646 13.3525 2.83311 16.4039 6.02842C19.2214 8.97875 19.338 13.5222 16.824 16.6035L18.9385 18.8177C19.32 19.2172 19.3054 19.8502 18.906 20.2316C18.5065 20.613 17.8735 20.5984 17.4921 20.199L15.3777 17.9849Z"
                  fill="#999999"
                />
              </g>
              <defs>
                <clipPath id="clip0_792_5063">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>

        <div className="language">
          <select
            id="languageSelect"
            className="form-control"
            onChange={handleLanguageChange}
          >
            <option value="english">English</option>
            <option value="spanish">Spanish</option>
          </select>
        </div>
      </div>
      <div id="renderHtml">
        <ErrorModal
          isOpen={isErrorModalOpen}
          onClose={closeErrorModal}
          errorMessage={errorMessage}
        />

        <EditModal
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          data={editModalData}
        />
      </div>
    </div>
  );
};
