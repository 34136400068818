import React, { useEffect, useContext } from "react";
import { UserContext } from "../index";

import { FormBuilder } from "./LeadGeneration/LeadForm";
import api from "../utils/api";
import Spinner from "./Spinner";

export const App = () => {
  const { accessToken, accountId, setAccountId } = useContext(UserContext);
  const getAccountInfo = async () => {
    try {
      //check token is valid & get account id
      const jsonResponseData = await api.getInfo(accessToken);
      const jsonResponse = await jsonResponseData.json();
      const { account_id } = jsonResponse;
      console.log(account_id);
      setAccountId(account_id);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAccountInfo();
  }, []);

  if (!accountId) {
    return <Spinner marginTop="calc(100% - 50px)" />;
  }

  return <FormBuilder />;
};

export default App;
