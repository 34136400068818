import React, { useEffect, useState, useContext } from "react";
import api from "../../utils/api";
import { UserContext } from "../../index";
import { ErrorModal } from "../LeadGeneration/ErrorModal";
import { getCurrentDay, isCurrentTimeInRange } from "../../utils/helper";

export const TwilioVoice = ({ data, tenantIdentifier }) => {
  const {
    info,
    leadId,
    setInfo,
    setCallId,
    customObj,
    tenantName,
    twilioNumber,
    contactNumber,
    isTenantAvailable,
    setTenantAvailable
  } = useContext(UserContext);

  const [sid, setSid] = useState(null);
  const [callStatus, setCallStatus] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [selectedNumber, setSelectedNumber] = useState("");
  const [callTimings, setCallTimings] = useState([]);

  const [loading, setIsLoading] = useState(false);
  const showLoader = () => setIsLoading(true);
  const hideLoader = () => setIsLoading(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    makeOutgoingCall();
  };

  const handleSelectChange = (e) => {
    let number = e.target.value;
    setSelectedNumber(number);
    // checkNumberIsOnline(callTimings, e.target.value);
  };

  // // MAKE AN OUTGOING CALL
  async function makeOutgoingCall() {
    var formData = new FormData();

    var callerId = document.getElementById("phone-number").value;

    if (!callerId || callerId.trim().length === 0) {
      return;
    }

    var numberToDial = formatPhoneNumber(callerId);
    console.log(numberToDial);

    var tenantNumber = formatPhoneNumber(selectedNumber);
    tenantNumber = tenantNumber.substring(1);

    console.log("contact_number", selectedNumber);

    // formData.append("tenant_number", "+12135684347");
    formData.append("tenant_number", twilioNumber);
    formData.append("caller_number", numberToDial);
    formData.append("tenant", tenantIdentifier);
    formData.append("contact_number", tenantNumber);

    //get staffid from user
    const storedUser = localStorage.getItem("user");
    const { staffid } = JSON.parse(storedUser);
    formData.append("staff_id", staffid);

    try {
      showLoader();
      const jsonResponseData = await api.connectCall(formData);
      const jsonResponse = await jsonResponseData.json();
      const { sid } = jsonResponse;
      setSid(sid);
      setCallId(sid);
      if (leadId) {
        console.log(leadId);
        updateLeadMeta(leadId, sid);
      }
      console.log(sid);
      setCallStatus("Initiated");
      setCallingInfo(sid);
      hideLoader();
    } catch (e) {
      hideLoader();
      openErrorModal(
        "Unable to create record: The phone number you are attempting to call is not valid. "
      );
    }
  }

  function formatPhoneNumber(phoneNumber) {
    // Remove all non-digit characters
    console.log("phone_number", phoneNumber);
    const digitsOnly = phoneNumber.replace(/\D/g, "");

    // Check if the phone number has a country code
    if (digitsOnly.length === 10) {
      return `+1${digitsOnly}`;
    } else {
      return `+${digitsOnly}`;
    }
  }

  const isValidNumber = (phoneNumber) => {
    const regex = /^(\+\d{1,15})$/;

    if (regex.test(phoneNumber)) {
      console.log("Phone number is valid");
      return true;
    } else {
      console.log("Phone number is not valid");
      return false;
    }
  };

  const getCallStatusUpdate = async () => {
    var formData = new FormData();
    formData.append("CallSid", sid);
    formData.append("tenant_name", tenantIdentifier);

    try {
      const jsonResponseData = await api.getCallStatus(formData);
      const jsonResponse = await jsonResponseData.json();
      const { data } = jsonResponse;

      const { call_status } = data;
      setCallStatus(call_status);

      if (call_status == "completed" || call_status == "no-answer") {
        setSid(null);
        removeCallingInfo();
        startTimer();
      }

      console.log(call_status);
    } catch (e) {
      // hideLoader();
      console.log(e);
    }
  };

  const setCallingInfo = (call_sid) => {
    const { chat_id } = customObj;
    var params = {
      sid: call_sid,
      chatId: chat_id
    };

    info.set(chat_id, params);
    setInfo(info);
  };

  const removeCallingInfo = () => {
    const { chat_id } = customObj;
    info.set(chat_id, null);
    setInfo(info);
  };

  const checkCallStatus = () => {
    const { chat_id } = customObj;
    var callStatus = info.get(chat_id);
    if (callStatus) {
      const { sid, chatId } = callStatus;

      if (chatId === chat_id) {
        if (sid) {
          setSid(sid);
        }
      } else {
        setSid(null);
        setCallStatus(null);
      }
    } else {
      setSid(null);
      setCallStatus(null);
    }
  };

  const startTimer = () => {
    setIsActive(true);
    setTimeout(() => {
      setIsActive(false);
    }, 60000);
  };

  const stopTimer = () => {
    setIsActive(false);
  };

  const openErrorModal = (message) => {
    setErrorMessage(message);
    setIsErrorModalOpen(true);
  };

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorMessage("");
  };

  const getTenantAvailabilityTiming = (timeZone) => {
    const { tenant_name } = customObj;
    if (tenant_name) {
      let day = getCurrentDay(timeZone);
      fetchTenantAvailabiltyTiming(tenant_name, day);
    }
  };

  const fetchTenantAvailabiltyTiming = async (tenant_name, day) => {
    try {
      const jsonResponseData = await api.getTenantAvailabilityTiming(
        tenant_name,
        day
      );
      const jsonResponse = await jsonResponseData.json();
      setCallTimings(jsonResponse.data);

      const availableItem = jsonResponse.data.find(
        (item) =>
          item.number === selectedNumber &&
          item.availability === "1" &&
          isCurrentTimeInRange(item.time_from, item.time_to, item.timezone) &&
          item.number != null
      );

      if (availableItem !== undefined) {
        setIsActive(false);
        setTenantAvailable(true);
        setSelectedNumber(availableItem.number);
      } else {
        setIsActive(true);
        setTenantAvailable(false);
      }
    } catch (e) {
      console.error(e); // It's better to use console.error for errors
    }
  };

  const updateLeadMeta = async (leadId, callId) => {
    try {
      var formData = new FormData();
      formData.append("lead_id", leadId);
      formData.append("call_id", callId);
      formData.append("tenant_name", tenantIdentifier);

      const jsonResponseData = await api.updateLeadMeta(formData);
      const jsonResponse = await jsonResponseData.json();
      console.log(jsonResponse);
    } catch (e) {
      console.log(e);
    }
  };

  const checkNumberIsOnline = (dataArray, selectedNumber) => {
    const item = dataArray.find((item) => item.number == selectedNumber);
    if (item) {
      const isAvailable =
        isCurrentTimeInRange(item.time_from, item.time_to, item.timezone) &&
        item.availability == "1";
      setIsActive(!isAvailable);
      setTenantAvailable(isAvailable);
    }
  };

  const getTimeZone = () => {
    let timeZone;
    if (contactNumber && contactNumber.length > 0) {
      timeZone =
        contactNumber.find((contact) => contact.number === selectedNumber)
          ?.timezone || "";
    }
    return timeZone;
  };

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    let interval;
    if (sid) {
      interval = setInterval(() => {
        getCallStatusUpdate();
      }, 2000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [sid]);

  useEffect(() => {
    checkCallStatus();
    //reset timer when user has changed or switch the.
    // stopTimer();
    //update tenant availability timings when the chat changes
    setIsActive(true);
    setTenantAvailable(false);
    let timeZone = getTimeZone();
    if (timeZone) {
      getTenantAvailabilityTiming(timeZone);
    }

    return () => {
      // clearInterval(interval);
    };
  }, [customObj]);

  //use effect to set selectedContact when there is only one number in list
  useEffect(() => {
    if (contactNumber && contactNumber.length > 0) {
      setSelectedNumber(contactNumber[0].number);
    }
  }, [contactNumber]);

  useEffect(() => {
    let timeZone = getTimeZone();
    if (timeZone) {
      getTenantAvailabilityTiming(timeZone);
    }
  }, [selectedNumber]);

  return (
    <div className={data}>
      <div
        className="form-loader form-loader-submit"
        style={{ display: loading ? "flex" : "none" }}
      >
        <div className="preload-wrapper">
          <div className="preload-loader-wrapper">
            <div className="preload-loader"></div>
          </div>
        </div>
      </div>

      <div id="call-controls" className="form-wrapper">
        <p
          className={`${
            isTenantAvailable ? "call-available" : "call-not-available"
          }`}
        >
          {isTenantAvailable
            ? "Available for the call"
            : "Not available for the call"}
        </p>
        <form>
          <div class="form-group">
            <label class="fb-select-label">Contact Numbers:</label>
            {contactNumber && contactNumber.length > 1 ? (
              <div>
                <select
                  className="form-control"
                  value={selectedNumber}
                  onChange={handleSelectChange}
                >
                  {contactNumber.map((contact) => (
                    <option key={contact.id} value={contact.number}>
                      {contact.name ? contact.name : contact.number}
                    </option>
                  ))}
                </select>
              </div>
            ) : contactNumber && contactNumber.length === 1 ? (
              <label class="fb-select-label"> {contactNumber[0].name}</label>
            ) : null}
          </div>

          <label class="fb-select-label" for="phone-number">
            Enter a phone number
          </label>
          <input
            class="form-control"
            id="phone-number"
            required="required"
            aria-required="true"
            type="text"
            placeholder="+15552221234"
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "15px"
            }}
          >
            <button
              className="primary-btn"
              id="button-call"
              type="submit"
              // onClick={handleSubmit}
              onClick={!isActive ? handleSubmit : (e) => e.preventDefault()}
              disabled={isActive || sid}
            >
              Call
            </button>
            <div id="call-status" className="hide">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <circle cx="12.8057" cy="12.8057" r="12.8057" fill="#80EB9E" />
                <path
                  d="M10.4586 15.9192C10.5047 15.8637 10.5334 15.8228 10.5683 15.7884C13.0575 13.3388 15.5469 10.8897 18.0365 8.44122C18.2318 8.2491 18.4495 8.14115 18.7306 8.21868C18.8305 8.24658 18.9219 8.29821 18.9968 8.36903C19.0716 8.43985 19.1276 8.52769 19.1598 8.6248C19.1921 8.72192 19.1995 8.82534 19.1816 8.92596C19.1636 9.02658 19.1208 9.12131 19.0569 9.20182C19.0262 9.24042 18.9933 9.27729 18.9583 9.31223C16.2923 11.9357 13.6264 14.5587 10.9607 17.1811C10.7078 17.4296 10.4219 17.4836 10.1486 17.3366C10.1117 17.3179 10.0777 17.2941 10.0476 17.2659C8.89177 16.1306 7.73724 14.9938 6.58395 13.8555C6.35948 13.6322 6.3732 13.2929 6.57747 13.05C6.67914 12.9292 6.82357 12.8509 6.98163 12.8308C7.1397 12.8107 7.29964 12.8502 7.42921 12.9415C7.48565 12.9833 7.53869 13.0293 7.58784 13.0792C8.50851 13.9836 9.42859 14.8884 10.3481 15.7938C10.3803 15.8264 10.4082 15.8623 10.4586 15.9192Z"
                  fill="white"
                />
              </svg>
              <label>{callStatus}</label>
              <br />
            </div>
          </div>
        </form>
        <ErrorModal
          isOpen={isErrorModalOpen}
          onClose={closeErrorModal}
          errorMessage={errorMessage}
        />
      </div>
    </div>
  );
};
